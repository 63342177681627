.announcement_bar_main {
  width: 100%;
  text-align: center;
  padding: 10px;
  background-color: #040715;
  color: #fff;
}


.xi-headerContent {
  max-width: 900px;
  margin: 0 auto;
  padding-top: 60px;
  text-align: center
}

.xi-headerContent h1 {
  font-size: 50px;
  font-weight: 700;
  margin: 0 auto 10px auto;
  max-width: 820px;
  color: #fff
}


.xi-headerContent h1 span {
  color: #fff;
  display: inline-block
}

#typed-home {
  margin-left: 10px;
}

.xi-headerContent p {
  font-size: 17px;
  color: #fff;
  margin-bottom: 5px;
}


.mainbox {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: 800px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 160px;
  background-color: rgb(255, 255, 255);
  transition: all 0.3s ease;
  padding: 0 20px;
}

.checkbox:focus {
  border: none;
  outline: none;
}

.checkbox {
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 17px;
  top: 10px;
  z-index: 9;
  cursor: pointer;
  appearance: none;
}

.search_input {
  box-sizing: border-box;
  height: 100%;
  width: 85%;
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.5rem;
  color: #000;
  transition: all 0.3s ease;
  padding: 0 10px;
}

.iconContainer.rounded_icon {
  /* background-color: #FF6955; */
  border-radius: 50%;
  height: 50px;
  color: #fff;
}

.search_input::placeholder {
  color: rgba(0, 0, 0, 0.776);
}

.iconContainer {
  box-sizing: border-box;
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #425CD9;
}

ul.category_searches.search_page li a {
  background: rgb(99 124 247 / .08);
  color: #637CF7;
}

ul.category_searches.search_page li a:hover {
  background: rgba(99, 124, 247, 0.829);
  color: #fff;
}

.search_icon {
  box-sizing: border-box;
  fill: rgb(0, 0, 0);
  font-size: 1.3em;
}

ul.category_searches {
  padding-left: 0;
  list-style: none;
  display: inline;
}

ul.category_searches li {
  display: inline;
}

ul.category_searches li a {
  display: inline-block;
  font-weight: 400;
  border-radius: 20px;
  transition: all ease 0.3s;
  color: rgb(255 255 255 / .8);
  background: rgb(255 255 255 / .2);
  border: none;
  margin: 5px 3px;
  font-size: 14px;
  padding: 4px 10px;
}

ul.category_searches li a:hover {
  color: rgb(255 255 255 / .9);
  background: rgb(99 124 247 / .08);
}

.itembrand {
  position: relative;
}

.itembrand:before {
  content: "";
  display: block;
  position: absolute;
  left: -40px;
  top: 0;
  width: 6px;
  height: 24px;
  background: #637CF7;
  border-radius: 0 8px 8px 0;
}

.mainbox.search_page {
  background-color: #838cf3;
  color: #fff;
}

.mainbox.search_page .search_input,
.mainbox.search_page .search_input::placeholder {
  color: #fff;
}

.iframe_custom_height {
  height: calc(100vh - 80px);
}

.commision_bg_image {
  background-image: url('../Images/Seller/commissionbg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.custom_before_start:before {
  position: absolute;
  content: '';
  display: block;
  width: 150px;
  height: 150px;
  background: #ffffff61;
  top: -3%;
  left: -3%;
  border-radius: 50%;
  transform: translate(-40%, -40%);
}

.custom_after_start:after {
  position: absolute;
  content: '';
  display: block;
  width: 150px;
  height: 150px;
  background: #0000002e;
  bottom: -3%;
  right: -3%;
  border-radius: 50%;
  transform: translate(40%, 40%);
}

.custom_svg_color_main .custom_svg_color {
  background-color: #F0F2FF;
  color: #425CD9;
}

.custom_svg_color_main:hover .custom_svg_color {
  background-color: #425CD9;
  color: #fff;
}

@media screen and (max-width: 991px) {
  .xi-headerContent {
    padding-top: 40px;
  }

  .xi-headerContent p {
    font-size: 14px;
  }

  .xi-headerContent h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 749px) {
  .search_input {
    font-size: 1rem;
    width: 80%;
  }

  .mainbox {
    padding: 0 10px;
    height: 70px;
  }

  .iconContainer {
    width: 30px;
  }

  .iconContainer.rounded_icon {
    width: 50px;
  }
}